import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsPage, DsvsSearchableReadEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HalCrudServiceImpl } from '../hal/hal.crud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { LanguageHalItem, LanguageHalPage } from '../hal/language.hal.item';
import { EFileTypes } from '../interfaces/dtos/enums/EFileTypes';
import { Id } from '../interfaces/dtos/Id';
import { Language } from '../interfaces/dtos/language';
import { NextVersion } from '../interfaces/dtos/nextversion';

export interface LanguageService extends DsvsSearchableReadEntityService<Language> {
  update(language: Language): Observable<LanguageHalItem>;

  create(language: Language): Observable<LanguageHalItem>;

  nextVersion(languageId: Id, version: string, parentVersion: string, increaseMajor: boolean): Observable<NextVersion>;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageServiceImpl extends
  HalCrudServiceImpl<Language, LanguageHalItem, LanguageHalPage>
  implements LanguageService {
  private backend: string = environment.backend;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, private http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'language', collection: 'languages'},
      LanguageHalPage,
      LanguageHalItem);
  }

  update(language: Language): Observable<LanguageHalItem> {
    return this.execute<LanguageHalItem>((v1Client: HalClient) => v1Client
      .typedPut<Language, LanguageHalItem>(this.itemConstructor, language, this.relation.single, <any>{entityId: language.id})
    );
  }

  create(language: Language): Observable<LanguageHalItem> {
    return this.execute<LanguageHalItem>((v1Client: HalClient) => v1Client
      .typedPost<Language, LanguageHalItem>(this.itemConstructor, language, this.relation.single)
    );
  }

  nextVersion(languageId: Id, version: string, parentVersion: string, increaseMajor: boolean): Observable<NextVersion> {
    return this.http.get<NextVersion>(
      this.backend
      + this.relation.collection
      + '/'
      + languageId
      + `/next-version?parentVersion=${parentVersion}&version=${version}&increaseMajor=${increaseMajor}`);
  }

  getDataLazy(dataGetServiceRoute: string, jwt: string, lazySearchParams: DsvsPage): Observable<LanguageHalPage> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/hal+json", "application/json');
    let params = new HttpParams().set('page',
      lazySearchParams.number + '&size=' + lazySearchParams.size + '&sort=' + lazySearchParams.sort[0].property + ',' + lazySearchParams.sort[0].order);
    return this.http.get<LanguageHalPage>(this.backend + dataGetServiceRoute, {headers, params});
  }

  getActiveLanguages(): Observable<Language[]>{
    return this.http.get<Language[]>(this.backend + "languages/activelanguages")
  }

  /**
   * @description All NePosia forms will be prepared to get translated into the new activated language.
   */
  public translateAllNeposiaForms(activatedLanguage: Language) {
    return this.http.post(this.backend + "languages/neposiaTranslation", activatedLanguage);
  }

  translateForm(version: String, targetlanguage: String): Observable<HttpResponse<Blob>> {
    return this.http.get(this.backend + "languages/" + version + "/download/" + targetlanguage + "?fileType=" + EFileTypes.PDF_MUSTER,
      {responseType: 'blob', observe: 'response'});
  }

}
